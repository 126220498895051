import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconOutlink } from '@apollo/icons/default/IconOutlink.svg';
import { useQuery, useMutation, MutationResult, useApolloClient, gql } from '@apollo/client';
import moment from 'moment';
import Config from '../../config';
import { groupBy } from 'lodash';
import Drawer from '../../components/Drawer';
import pluralize from '../../helpers/pluralize';
import capitalize from '../../helpers/capitalize';
import * as Types from '../../types';
import { ForwardIcon, LockClosedIcon, LockOpenIcon, TrashIcon } from '@heroicons/react/24/outline';
import { LockAccountConfirmationModal } from './LockAccountConfirmationModal';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import Page from '../../components/Page';
import { Button, Tooltip } from '@chakra-ui/react';
import { CONNECTION_FRAGMENT, SSO } from '../../components/Account/SSO/SSO';
import { DeleteAccountConfirmationModal } from './DeleteAccountConfirmationModal';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';

const ENTERPRISE_PILOT_SKU = 'sub-engine-ent-pilot';

export const ACCOUNT_METADATA_FRAGMENT = gql`
    fragment Metadata_AccountMetadataCommonFragment on Account {
        id
        internalID
        secondaryIDs
        name
        billingContactEmail
        createdAt
        avatarUrl
        sso {
            provider
            idpid
            defaultRole
        }
        ssoV2 {
            defaultRole
            allConnections {
                ...Metadata_ConnectionFragment
            }
        }
        billingInfo {
            cardType
            lastFour
        }
        isLocked
        lockDetails {
            actor
            reason
            type
            timestamp
        }
    }
    ${CONNECTION_FRAGMENT}
`;

const ACCOUNT_SUBSCRIPTION_PLAN_FRAGMENT = gql`
    fragment AccountSubscriptionPlanCommonFragment on Account {
        subscriptions {
            uuid
            state
            activatedAt
            canceledAt
            plan {
                id
                name
            }
        }
        currentPlan {
            id
            name
            tier
        }
        currentSubscription {
            uuid
            expiresAt
            trialExpiresAt
            activatedAt
        }
        isOnTrial
        isOnExpiredTrial
        hasBeenOnTrial
    }
`;

export const ACCOUNT_METADATA_QUERY = gql`
    query Metadata_Account__MetadataQuery($accountId: ID!) {
        account(id: $accountId) {
            ...Metadata_AccountMetadataCommonFragment
            ...AccountSubscriptionPlanCommonFragment
        }
    }
    ${ACCOUNT_METADATA_FRAGMENT}
    ${ACCOUNT_SUBSCRIPTION_PLAN_FRAGMENT}
`;

const TERMINATE_ACTIVE_SUBSCRIPTIONS = gql`
    mutation Metadata_Account__TerminateActiveSubscriptions($accountId: ID!) {
        account(id: $accountId) {
            terminateSubscriptions {
                ...Metadata_AccountMetadataCommonFragment
                ...AccountSubscriptionPlanCommonFragment
            }
        }
    }
    ${ACCOUNT_METADATA_FRAGMENT}
    ${ACCOUNT_SUBSCRIPTION_PLAN_FRAGMENT}
`;

const ACCOUNT_START_ENTERPRISE_PILOT_MUTATION = gql`
  mutation StartEnterprisePilot($accountId: ID!) {
    account(id: $accountId) {
      setPlan(id: "${ENTERPRISE_PILOT_SKU}")
    }
  }
`;

const ACCOUNT__METADATA_UNLOCK_MUTATION = gql`
    mutation Account__Metadata_UnlockAccount($accountId: ID!) {
        account(id: $accountId) {
            unlock {
                isLocked
            }
        }
    }
`;

const ACCOUNT__METADATA_EXTEND_MUTATION = gql`
    mutation Metadata_Account__ExtendTrial($accountId: ID!) {
        account(id: $accountId) {
            extendTrial {
                id
            }
        }
    }
`;

interface MutationStatusProps {
    result: MutationResult<unknown>;
}
const MutationStatus: FunctionComponent<MutationStatusProps> = ({ result }) => (
    <>
        {result.loading && <p className="italic">Loading...</p>}
        {result.error && <p className="italic">{result.error.message}</p>}
        {!result.loading && result.called && !result.error && <p className="italic">Updated!</p>}
    </>
);

function formatDate(timestamp: string | number) {
    return moment(timestamp).format('M/D/YY h:mma');
}

const Metadata: FunctionComponent<{ accountId: string }> = ({ accountId }) => {
    const [lockModalState, setLockModalState] = useState<boolean>(false);
    const [deleteModalState, setDeleteModalState] = useState<boolean>(false);
    const [confirmModalState, setConfirmModalState] = useState<boolean>(false);
    const [extendModalState, setExtendModalState] = useState<boolean>(false);
    const [enterprisePilotModalState, setEnterprisePilotModalState] = useState<boolean>(false);

    const { user: me } = useIsLoggedIn();

    const { cache } = useApolloClient();
    const { data, loading, error } = useQuery<
        Types.Metadata_Account__MetadataQuery,
        Types.Metadata_Account__MetadataQueryVariables
    >(ACCOUNT_METADATA_QUERY, {
        variables: { accountId },
    });

    const account = data?.account;

    const [terminateSubscriptions, terminateSubscriptionsResult] = useMutation<
        Types.Metadata_Account__TerminateActiveSubscriptions,
        Types.Metadata_Account__TerminateActiveSubscriptionsVariables
    >(TERMINATE_ACTIVE_SUBSCRIPTIONS, {
        variables: { accountId },
    });

    const [startEnterprisePilot, startEnterprisePilotResult] = useMutation<
        Types.StartEnterprisePilot,
        Types.StartEnterprisePilotVariables
    >(ACCOUNT_START_ENTERPRISE_PILOT_MUTATION, {
        variables: { accountId },
        onCompleted: () => {
            cache.evict({
                id: cache.identify({
                    __typename: 'Account',
                    id: accountId,
                }),
                fieldName: 'currentPlan',
            });
        },
    });

    const [unlockAccount] = useMutation<
        Types.Account__Metadata_UnlockAccount,
        Types.Account__Metadata_UnlockAccountVariables
    >(ACCOUNT__METADATA_UNLOCK_MUTATION);

    const [extendTrial] = useMutation<
        Types.Metadata_Account__ExtendTrial,
        Types.Metadata_Account__ExtendTrialVariables
    >(ACCOUNT__METADATA_EXTEND_MUTATION);

    if (loading) return <Page>Loading...</Page>;
    if (error) return <Page>{error.message}</Page>;
    if (!account)
        return (
            <Page>
                Account <p>{accountId}</p>'s metadata could not be found.
            </Page>
        );

    const { subscriptions, currentPlan, currentSubscription, isOnTrial } = account;
    const eligibleForTrial =
        currentPlan.tier &&
        [Types.BillingPlanTier.COMMUNITY, Types.BillingPlanTier.ONE, Types.BillingPlanTier.USAGE_BASED].includes(
            currentPlan.tier
        );

    const isOnEnterprisePilot = currentPlan.id === ENTERPRISE_PILOT_SKU;
    const hasBeenOnEnterprisePilot =
        subscriptions &&
        (subscriptions as Array<Types.BillingSubscription>).filter((s) => s?.plan?.id === ENTERPRISE_PILOT_SKU).length >
            0;

    // only allow accounts with either: an expired trial and NOT on an enterprise plan, OR an account currently on a trial
    const canExtendTrial =
        (currentPlan.tier !== Types.BillingPlanTier.ENTERPRISE && account.isOnExpiredTrial) || isOnTrial;

    return (
        <Page>
            <DeleteAccountConfirmationModal
                show={deleteModalState}
                setShow={(state) => setDeleteModalState(state)}
                accountId={accountId}
            />
            <LockAccountConfirmationModal
                show={lockModalState}
                setShow={(state) => setLockModalState(state)}
                accountId={accountId}
            />
            <ConfirmationModal
                callback={() => {
                    startEnterprisePilot();
                    setEnterprisePilotModalState(false);
                }}
                show={enterprisePilotModalState}
                setShow={(state) => setEnterprisePilotModalState(state)}
                message="Are you sure you would like to start an enterprise pilot for this account? This is hard to undo without contacting engineering and will prevent transitioning to other plan types (such as Dedicated)."
            />
            <ConfirmationModal
                callback={() => {
                    unlockAccount({
                        variables: { accountId },
                        refetchQueries: [ACCOUNT_METADATA_QUERY, 'Header_Account__MetadataQuery'],
                    });
                    setConfirmModalState(false);
                }}
                show={confirmModalState}
                setShow={(state) => setConfirmModalState(state)}
                message="Are you sure you would like to unlock this account?"
            />
            <ConfirmationModal
                callback={() => {
                    extendTrial({
                        variables: { accountId },
                        refetchQueries: [ACCOUNT_METADATA_QUERY, 'Header_Account__MetadataQuery'],
                    });
                    setExtendModalState(false);
                }}
                show={extendModalState}
                setShow={(state) => setExtendModalState(state)}
                message={`Would you like to extend the enterprise trial for account ${account.name}?`}
            />

            <div className="flex flex-col space-y-2 pb-2">
                <div>
                    <p className="font-bold">Provisioned:</p> {account.createdAt && formatDate(account.createdAt)}
                </div>
                <div>
                    <div>
                        <p className="font-bold">Internal ID:</p> {account.internalID}
                    </div>
                    {account.secondaryIDs.length > 0 && (
                        <div>
                            <div className="italic">
                                <p>Secondary IDs:</p> {account.secondaryIDs.join(', ')}
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <Link to={`/p/${currentPlan.id}`}>
                        <p className="font-bold">Current plan:</p> {currentPlan.name}{' '}
                    </Link>
                </div>
                {currentSubscription?.expiresAt && (
                    <div className="italic text-xs">
                        <div>Subscription expiration: {moment(currentSubscription.expiresAt).format('LL')}</div>
                    </div>
                )}
                {currentSubscription?.trialExpiresAt && (
                    <div className="italic text-xs">
                        <div>Trial expiration: {moment(currentSubscription.trialExpiresAt).format('LL')}</div>
                    </div>
                )}
                {canExtendTrial && (
                    <div>
                        <Button type="button" variant={'primary'} size={'sm'} onClick={() => setExtendModalState(true)}>
                            <ForwardIcon className="h-4 w-4" />
                            Extend Trial
                        </Button>
                    </div>
                )}
                {isOnTrial && currentSubscription?.trialExpiresAt && (
                    <div>
                        <button
                            onClick={() => {
                                terminateSubscriptions();
                            }}
                            className="disabled:cursor-not-allowed disabled:opacity-50 inline-flex items-center gap-x-1.5 rounded bg-grey-dark px-2.5 py-1.5 text-sm font-bold shadow-sm hover:bg-grey-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-dark text-white"
                        >
                            Move to Free
                        </button>
                        <MutationStatus result={terminateSubscriptionsResult} />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-2 pb-2">
                <p className="font-bold">Enterprise Pilot:</p>{' '}
                {isOnEnterprisePilot
                    ? 'Currently on pilot'
                    : hasBeenOnEnterprisePilot
                      ? 'Previously on pilot'
                      : 'Never on pilot'}
                {isOnEnterprisePilot && (
                    <>
                        <p>Enterprise pilot started:</p>
                        {moment(currentSubscription?.activatedAt).format('M/D/YY h:mma')}{' '}
                        <p className="italic">{moment(currentSubscription?.activatedAt).fromNow()}</p>
                    </>
                )}
                {isOnEnterprisePilot && (
                    <div>
                        <Button
                            onClick={() => {
                                const confirmed = window.confirm(
                                    `Are you sure you want to move this account to the free Community plan? They will immediately lose access to all paid features.

Some Enterprise customers have two accounts (one in active use, one for billing) for historic reasons. Please check if this is the active duplicate of another account before proceeding.

If you're not sure what to do, please get in touch with Customer Success, Finance, or Engineering.`
                                );
                                if (confirmed) {
                                    terminateSubscriptions();
                                }
                            }}
                            variant={'destructive'}
                            size={'sm'}
                        >
                            Move to Free
                        </Button>{' '}
                        <MutationStatus result={terminateSubscriptionsResult} />
                    </div>
                )}
                {eligibleForTrial && (
                    <div>
                        <Button
                            onClick={() => setEnterprisePilotModalState(true)}
                            variant={'primary'}
                            size={'sm'}
                            isDisabled={accountId === 'apollo'}
                        >
                            Start {hasBeenOnEnterprisePilot && 'Another '}Enterprise Pilot (DEPRECATED)
                        </Button>{' '}
                        <MutationStatus result={startEnterprisePilotResult} />
                    </div>
                )}
                <div className="flex space-x-2">
                    <div>
                        {!account.isLocked ? (
                            <Button
                                type="button"
                                variant={'destructive'}
                                size={'sm'}
                                onClick={() => setLockModalState(true)}
                                isDisabled={accountId === 'apollo'}
                            >
                                <LockClosedIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                Lock Account
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                variant={'primary'}
                                size={'sm'}
                                onClick={() => setConfirmModalState(true)}
                            >
                                <LockOpenIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                                Unlock Account
                            </Button>
                        )}
                    </div>
                    <div>
                        <Button
                            type="button"
                            variant={'destructive'}
                            size={'sm'}
                            onClick={() => setDeleteModalState(true)}
                            isDisabled={
                                accountId === 'apollo' ||
                                isOnEnterprisePilot ||
                                currentPlan.tier === Types.BillingPlanTier.ENTERPRISE ||
                                me?.internalAdminRole !== Types.InternalMdgAdminRole.INTERNAL_MDG_SUPER_ADMIN
                            }
                        >
                            <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            {
                                // On trial
                                isOnEnterprisePilot ? (
                                    <Tooltip label="On Enterprise trial, move to free.">Delete Account</Tooltip>
                                ) : // Active enterprise sub
                                currentPlan.tier === Types.BillingPlanTier.ENTERPRISE ? (
                                    <Tooltip label="Not allowed on active Enterprise subscription.">
                                        Delete Account
                                    </Tooltip>
                                ) : // Not super admin
                                me?.internalAdminRole !== Types.InternalMdgAdminRole.INTERNAL_MDG_SUPER_ADMIN ? (
                                    <Tooltip label="Must be 'INTERNAL_MDG_SUPER_ADMIN'">Delete Account</Tooltip>
                                ) : (
                                    'Delete Account'
                                )
                            }
                        </Button>
                    </div>
                </div>
            </div>
            <hr className="pb-2" />
            <div className="pb-2">
                <SSO accountId={account.id} />
            </div>
            <hr className="pb-2" />
            {subscriptions?.length ? (
                <div>
                    <>
                        {account.internalID && (
                            <div className="pb-2">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex-initial flex-row items-center hover:no-underline inline-block text-white bg-btn-primary hover:bg-btn-primary-hover mt-4 px-3 py-1 font-semibold rounded border border-primary text-sm"
                                    href={`${Config.recurlyURL}accounts/${
                                        account.internalID.includes('.') ? '' : 'apollo.'
                                    }${account.internalID}`}
                                >
                                    <div className="flex flex-row items-center">
                                        Visit account in Recurly
                                        <IconOutlink className="ml-2 h-3 w-3 stroke-icon-primary" />
                                    </div>
                                </a>
                            </div>
                        )}
                        <div>
                            <p className="font-bold">Billing email:</p>
                            {account.billingContactEmail || 'No recurly email.'}
                        </div>
                        <div>
                            <p className="font-bold">Billing info:</p>{' '}
                            {account.billingInfo
                                ? `${account.billingInfo.cardType} ${account.billingInfo.lastFour}`
                                : 'No billing info.'}
                        </div>
                        {Object.entries(groupBy(subscriptions as Array<Types.BillingSubscription>, (s) => s.state)).map(
                            ([subState, subs]) => (
                                <div key={subState} style={{ marginTop: 20 }}>
                                    <Drawer
                                        defaultOpen={subState === Types.SubscriptionState.ACTIVE}
                                        label={<p>{pluralize(`${capitalize(subState)} Subscription`, subs.length)}</p>}
                                    >
                                        {subs.map((s) => (
                                            <React.Fragment key={s.activatedAt}>
                                                <div>
                                                    <Link to={`/p/${s.plan.id}`}>
                                                        <span>{s.plan.id}</span> <p className="italic">{s.state}</p>
                                                    </Link>
                                                    <div>
                                                        <p className="italic">{s.plan.name}</p>
                                                    </div>
                                                    <div>
                                                        <div className="italic">
                                                            <p>Activated:</p> {formatDate(s.activatedAt)}
                                                        </div>
                                                    </div>
                                                    {s.canceledAt ? (
                                                        <div>
                                                            <p className="italic">
                                                                <p>Canceled:</p> {formatDate(s.canceledAt)}
                                                            </p>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </Drawer>
                                </div>
                            )
                        )}
                    </>
                </div>
            ) : null}
        </Page>
    );
};

export { Metadata };
