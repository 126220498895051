import { SsoConnectionStateV2 } from '../../../types';

export const stateVariants: Map<SsoConnectionStateV2, string> = new Map();
stateVariants.set(SsoConnectionStateV2.ENABLED, 'success');
stateVariants.set(SsoConnectionStateV2.ARCHIVED, 'error');
stateVariants.set(SsoConnectionStateV2.DISABLED, 'error');
stateVariants.set(SsoConnectionStateV2.STAGED, 'beta');
stateVariants.set(SsoConnectionStateV2.VALIDATED, 'blue');
stateVariants.set(SsoConnectionStateV2.INITIALIZED, 'subtle');

const stateOrdering: Map<SsoConnectionStateV2 | undefined, number> = new Map();
stateOrdering.set(SsoConnectionStateV2.ENABLED, 0);
stateOrdering.set(SsoConnectionStateV2.VALIDATED, 1);
stateOrdering.set(SsoConnectionStateV2.STAGED, 2);
stateOrdering.set(SsoConnectionStateV2.INITIALIZED, 3);
stateOrdering.set(SsoConnectionStateV2.DISABLED, 4);
stateOrdering.set(SsoConnectionStateV2.ARCHIVED, 5);
stateOrdering.set(undefined, 6);

function getSortOrder(state: SsoConnectionStateV2 | undefined): number {
    return stateOrdering.get(state) || 0;
}

type WithConnectionState = { stateV2?: SsoConnectionStateV2 };

export function sortConnectionsByState<T>(
    connections: Array<(T & WithConnectionState) | undefined>
): Array<T | undefined> {
    const sorted = [...connections];
    sorted.sort((c1, c2) => getSortOrder(c1?.stateV2) - getSortOrder(c2?.stateV2));

    return sorted;
}
