import { gql, useQuery } from '@apollo/client';
import { App__AuthQuery, InternalMdgAdminRole } from '../types';

const AUTH_QUERY = gql`
    query App__AuthQuery {
        me {
            id
            ... on User {
                email
                fullName
                internalAdminRole
            }
        }
    }
`;

export const useIsLoggedIn = (): {
    loading: boolean;
    isLoggedIn: boolean;
    user:
        | {
              id?: string;
              fullName?: string | null;
              email?: string | null;
              internalAdminRole?: InternalMdgAdminRole | null;
          }
        | undefined;
} => {
    const { data: { me } = {}, loading } = useQuery<App__AuthQuery>(AUTH_QUERY);
    if (me?.__typename !== 'User') {
        return { loading, isLoggedIn: false, user: undefined };
    }
    return (
        {
            loading,
            isLoggedIn: !!me?.id && !!me.internalAdminRole,
            user: {
                id: me.id,
                fullName: me.fullName,
                email: me.email,
                internalAdminRole: me.internalAdminRole,
            },
        } || undefined
    );
};
