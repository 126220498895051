import { gql, useMutation } from '@apollo/client';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Metadata__AccountDelete, Metadata__AccountDeleteVariables } from '../../types';
import { useState } from 'react';
import { ACCOUNT_METADATA_QUERY } from './Metadata';

const METADATA_ACCOUNT_DELETE = gql`
    mutation Metadata__AccountDelete($accountId: ID!) {
        account(id: $accountId) {
            hardDelete
        }
    }
`;

type DeleteAccountConfirmationModalProps = {
    show: boolean;
    setShow: (value: boolean) => void;
    accountId: string;
};

export function DeleteAccountConfirmationModal({ show, setShow, accountId }: DeleteAccountConfirmationModalProps) {
    const [deleteAccount, { loading, error }] = useMutation<Metadata__AccountDelete, Metadata__AccountDeleteVariables>(
        METADATA_ACCOUNT_DELETE,
        {
            refetchQueries: [ACCOUNT_METADATA_QUERY],
            onCompleted: () => setShow(false),
        }
    );

    let [confirmation, setConfirmation] = useState<string>('');

    return (
        <Modal isOpen={show} onClose={() => setShow(!show)}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody className="flex flex-col items-center rounded-lg shadow-sm space-y-2 text-center">
                    {error && (
                        <div className="bg-red-dark p-4 text-xs">
                            {error?.message.replace('Exception while fetching data (/account/hardDelete) : ', '')}
                        </div>
                    )}
                    <div>
                        <TrashIcon className="bg-red rounded-full h-12 w-12 p-2 mx-auto stroke-white" />
                    </div>
                    <div className="font-bold text-xl">Are you sure you want to delete this account?</div>
                    <div className="font-bold text-xl bg-secondary px-4 py-2 font-mono">{accountId}</div>
                    <div>
                        By clicking 'Delete Account' this account will be scheduled for deletion, which may take some
                        time. The customer will lose access to their account immediately, but it will remain recoverable
                        during this period.
                    </div>
                    <textarea
                        rows={1}
                        name="confirmation"
                        id="confirmation"
                        className="inline-flex w-full rounded border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-3"
                        onChange={(e) => setConfirmation(e.target.value)}
                        placeholder="confirm"
                        value={confirmation}
                    />
                    <div className="inline-flex space-x-4">
                        <Button
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded bg-grey-dark px-2.5 py-1.5 text-sm font-bold text-white shadow-sm hover:bg-grey-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                            onClick={() => setShow(false)}
                            variant={'primary'}
                            size={'sm'}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            isDisabled={confirmation.trim() !== 'confirm'}
                            onClick={() => {
                                if (confirmation.trim() !== 'confirm') {
                                    return;
                                }
                                deleteAccount({ variables: { accountId } });
                            }}
                            variant={'destructive'}
                            size={'sm'}
                            isLoading={loading}
                        >
                            <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Delete Account
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
